import React, { FC } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import "@fontsource/open-sans";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";

interface BoardingPass {
  boardingPassImage: any;
  boardingPassHeight: any;
  boardingPassAltText: any;
  boardingPassTransformOrigin: any;
  boardingPassTransform: any;
  boardingPassMarginRight?: any;
  boardingPassMarginBottom?: any;
}

const BoardingPass: FC<BoardingPass> = ({
  boardingPassImage,
  boardingPassAltText,
  boardingPassHeight,
  boardingPassMarginRight,
  boardingPassTransform,
  boardingPassTransformOrigin,
  boardingPassMarginBottom,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Image
        h={boardingPassHeight}
        src={boardingPassImage}
        alt={boardingPassAltText}
        transformOrigin={boardingPassTransformOrigin}
        transform={boardingPassTransform}
        marginRight={boardingPassMarginRight}
        marginBottom={boardingPassMarginBottom}
        onClick={onOpen}
        // _hover={{border:"4px solid black", borderRadius:"25"}}
        _hover={{ filter: 'brightness(0.5)' }} // Apply filter on hover to change brightness

      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent backgroundColor={"transparent"} h={{lg:"100%", md:"70%", sm:"50%"}}>
          <ModalCloseButton backgroundColor={"white"} mr={{lg:"-75%", md:"-30%", sm:"5%"}}/>
          <ModalBody
            w={"100%"}
            h={"100%"}
            display="flex"
            alignItems={{r:"center", sm:"none", md:"none"}}
            justifyContent="center"
          >
            <Image src={boardingPassImage} transform={"rotate(270deg)"} w={{sm:"40%", md:"150%", r:"100%"}}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BoardingPass;
