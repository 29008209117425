import React, { useState, useEffect } from "react";
import app from "../firebaseConfig";
import { getDatabase, ref, get } from "firebase/database";
import ArtTab from "../components/ArtTab/index.tsx";
import { Spinner } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";

function Read() {
  const [Art, setArt] = useState([]);

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means this effect runs only once, equivalent to componentDidMount

  const fetchData = async () => {
    try {
      const db = getDatabase(app);
      const dbRef = ref(db, "thirty8/art");
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        setArt(Object.values(snapshot.val()));
      } else {
        alert("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {Art.length > 0 ? (
        Art.map((item, index) => (
          <ArtTab
            key={index} // Adding key prop for React to efficiently update the list
            ArtLink={item.cloudtag}
            ArtSize={item.size}
            ArtPreview={item.artPreview}
            ArtTitle={item.artTitle}
            ArtID={index}
            ArtDesc={item.artDescription}
          />
        ))
      ) : (
        <Flex w={"100%"} h={"100%"} flexDir={"row"} justifyContent={"center"} alignItems={"center"}>
          <Spinner
            thickness='4px'
            speed='0.85s'
            emptyColor='gray.800'
            color='red.500'
            size='xl'
          />
        </Flex>
      )}
    </>
  );
}
  

export default Read;
