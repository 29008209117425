import React from "react";
import {
  Box,
  Button,
  Container,
  Text,
  Image,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { FC } from "react";
import "@fontsource/open-sans";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface DashboardTab {
  header: string;
  text: any;
  backgroundPicture: any;
  linkTo: string;
  className?: string;
}

const DashboardTab: FC<DashboardTab> = ({
  header,
  text,
  backgroundPicture,
  linkTo,
  className
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (className === "normal") {
      navigate(`/${linkTo}`);
    } else {
        window.open("https://946c61-91.myshopify.com/", "_blank");
    }
  };

  return (
    // <ChakraLink w={"100%"} h={"47%"} as={ReactRouterLink} to={"/" + linkTo} >
    <Flex
      w={"100%"}
      h={"100%"}
      minH={"13rem"}
      flexDir={"column"}
      backgroundImage={backgroundPicture}
      backgroundSize={"cover"}
      backgroundPosition={"center"}
      borderRadius={"5%"}
      fontFamily={"Open Sans"}
      _hover={{ filter: "brightness(0.6)", cursor: "pointer" }}
      onClick={() =>handleClick()} // Pass a function to onClick
    >
      <Flex
        width="100%"
        height="100%"
        borderRadius={"5%"}
        border={"4px solid rgba(0, 0, 0, 0.2)"}
        background="rgba(0, 0, 0, 0.4)" // Adjust opacity for the glass effect
        backdropFilter="blur(1px)" // Adjust blur radius for the glass effect
        flexDir={"column"}
        p={"4%"}
        color={"white"}
      >
        <Heading mb={"20px"}>{header}</Heading>
        <Text>{text}</Text>
      </Flex>
    </Flex>
    // </ChakraLink>
  );
};

export default DashboardTab;
