import React, { FC } from "react";
import { Flex, Image } from "@chakra-ui/react";
import previous from "../../assets/icons/previous.png";
import "@fontsource/open-sans";

interface PreviousHeaderProps {
  title: string;
}

const PreviousHeader: FC<PreviousHeaderProps> = ({ title }) => {
  const handleBackNavigation = () => {
    window.history.back();
  };

  return (
    <Flex
      w={"100%"}
      h={"10%"}
      flexDir={"row"}
      backgroundColor={"rgba(93, 7, 8, 0.7)"}
      borderTop={"4px solid rgba(0, 0, 0, 0.2)"}
      borderTopRadius={"16px"}
    >
      <Flex h={"100%"} w={"10%"} pl={"2%"} alignItems={"center"}>
        <Image
          src={previous}
          _hover={{ filter: "brightness(0.6)", cursor: "pointer" }}
          onClick={handleBackNavigation}
        />
      </Flex>
      <Flex
        w={"80%"}
        flexDir={"row"}
        alignItems={"center"}
        justifyContent={"space-around"}
      >
        <Flex color={"white"} fontSize={{ sm: "30px", r: "350%" }} fontWeight={"bold"}>
          {title}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PreviousHeader;
