import React, { useEffect, useState } from "react";
import "@fontsource/open-sans";
import phone from "../../assets/icons/phone.svg";
import { Flex, Text, Image, Grid, GridItem } from "@chakra-ui/react";
import background from "../../assets/38_globes.png";
import PreviousHeader from "../../components/PreviousHeader/index.tsx";
import ArtTab from "../../components/ArtTab/index.tsx";
import Read from "../../components/read.js";


import app from "../../firebaseConfig.js";
import { getDatabase, ref, get } from "firebase/database";


import B1 from "../../assets/IMG_6397.jpg";
import B2 from "../../assets/38_Magazine.jpg";
import B3 from "../../assets/DSC01548.JPG";
import B4 from "../../assets/DSC01985.JPG";
import B5 from "../../assets/DSC01703.JPG";
import B6 from "../../assets/CN_Tower.webp";


const ArtCollection = () => {
  return (
    <Flex
      backgroundImage={background}
      backgroundSize={{sm:"150%", r:"70%"}}
      backgroundPosition={"center"}
      top={0}
      left={0}
      w={"100%"}
      h={"100%"}
      borderRadius={"24"}
      p={"1.5%"}
      pt={"2%"}
      color={"black"}
      flexDir={"column"}
      alignItems={"center"}
    >
      <PreviousHeader title="38Art Collection" />
      <Flex
        h={"100%"}
        flexDir={"column"}
        w={"100%"}
        mt={"2%"}
        flexDirection={"row"}
        justifyContent={"center"}
        overflow={"auto"}
      >

        <Grid templateColumns= {{sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', r:'repeat(3, 1fr)'}} templateRows={['repeat(6, 1fr)', 'repeat(2, 1fr)']} gap={5} w={"100%"} flexDir={"row"} 
        justifyContent={"space-around"}
        justifyItems="center">
          <Read/>

        </Grid>

      </Flex>
    </Flex>
  );
};

export default ArtCollection;

