import { AspectRatio, Box, Flex } from "@chakra-ui/react";
import React, { useRef, useEffect, FC } from "react";

interface ArtViewer{
    cloudtag: string;
}

const ArtViewer: FC<ArtViewer> = ({ cloudtag }) => {

    const containerRef = useRef(null);

  useEffect(() => {
    if (window && containerRef.current) {
      window.cloudinary
        .galleryWidget({
          container: containerRef.current,
          cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
          mediaAssets: [
            {
              tag: `${cloudtag}`,
              crop: "cover",
            },
          ],
          radius: 1,
          carouselLocation: "right",
        })
        .render();
    }
  }, []);

  return (
    <Box width={{ sm: "100%", r: "80%" }} height="80%">
      <Box
        className="gallery-container"
        ref={containerRef}
        width="100%"
        height="100%"
        position="relative"
        backgroundColor= ""// Change this to your desired background color
      />
    </Box>
  );
};

export default ArtViewer;
