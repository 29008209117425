import { Flex, Text, Heading, Image } from "@chakra-ui/react";
import background from "../../assets/38_globes.png";
import { useParams } from "react-router-dom";
import PreviousHeader from "../../components/PreviousHeader/index.tsx";
import ArtViewer from "../../components/ArtViewer/index.tsx"

import React, { useState, useEffect } from "react";
import app from "../../firebaseConfig.js";
import { getDatabase, ref, get } from "firebase/database";
import { Cloudinary } from "@cloudinary/url-gen/index";

const ViewArt = () => {
  const { ArtID } = useParams();
  const ArtLink = localStorage.getItem("artlink");
  const ArtSize = localStorage.getItem("artsize");


  const [artAtIndex, setArtAtIndex] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const db = getDatabase(app);
      const dbRef = ref(db, "thirty8/art");
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const artData = Object.values(snapshot.val());
        if (artData && artData.length > ArtID) {
          setArtAtIndex(artData[ArtID]);
        } else {
          alert("Invalid index or no data available for the specified index");
        }
      } else {
        alert("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
    },
  });

  return (
    <>
      {artAtIndex && (
        <Flex
          backgroundImage={background}
          backgroundSize={{sm:"150%", r:"70%"}}
          backgroundPosition={"center"}
          top={0}
          left={0}
          w={"100%"}
          h={"100%"}
          borderRadius={"24"}
          p={"1.5%"}
          pt={"2%"}
          color={"black"}
          flexDir={"column"}
          alignItems={"center"}
          overflow={"auto"}
        >
          <PreviousHeader
            title={"ArtViewer"}
          />
          <Flex w={"100%"} h={"90%"} mt={"1%"} flexDir={{r:"row", sm:"column"}}>
            <Flex w={{r:"50%", sm:"100%"}} flexDir={"row"} justifyContent={"center"}>

              <ArtViewer cloudtag={ArtLink}/>
              
            </Flex>
            <Flex
              w={{r:"50%", sm:"100%"}}
              flexDir={"column"}
              pl={"2%"}
              pt={"3%"}
              color={"white"}
            >
              <Heading flexDir={"row"}>{artAtIndex.artTitle}</Heading>
              <Text fontSize={"100%"}>{artAtIndex.size}</Text>
              <Text fontSize={"100%"} fontStyle={"italic"}>
                Materials: {artAtIndex.artDescription}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default ViewArt;
