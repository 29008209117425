import {
  Image,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import "@fontsource/open-sans";
import React from "react";

import pilot from "../../assets/icons/pilot.svg";

const EventModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Image
        src={pilot}
        alt="Pilot Icon"
        h={{ sm: "1.7rem", md: "3rem", r: "50%" }}
        _hover={{ filter: "brightness(0.6)", cursor: "pointer" }}
        onClick={onOpen}
      ></Image>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ sm: "100vw", r: "30vw" }} // Adjust the maximum width as needed
          maxH={{ sm: "100vh", r: "50vh" }} // Fix the height to increase
          backgroundColor={"black"}
          display="flex"
          alignItems={{ r: "center", sm: "center", md: "center" }}
          justifyContent="center"
          paddingBottom="4" // Add padding beneath the Link
        >
          <ModalHeader fontFamily={"monospace"} color={"white"}>
            38AIRLINES EVENTS
            <ModalCloseButton backgroundColor={"black"} size="lg" />
          </ModalHeader>
          <ModalBody textAlign={"center"} color={"white"} fontStyle={"italic"}>
          <Link
        href="https://sk38dz9s.wixsite.com/38events" target="_blank"
        color="white.500"
        textDecoration="underline"
        _hover={{ color: 'red.700', textDecoration: 'none' }}
      >
        Click here to navigate to our events page
      </Link>
            {/* <Text
             h={"30px"}
            >
              Click here to naviagte to our events page
            </Text> */}
            {/* <FormikExample /> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EventModal;
