import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { About, Dashboard, Loading, Home, Magazine, Gallery, Watch, ArtCollection, ViewArt, ViewMag, Shop, ViewWatch } from "./Routes.tsx";
import { Box, ChakraProvider, Flex } from "@chakra-ui/react";
import { theme } from "../src/themes.ts"
import "@fontsource/open-sans";
import TVFrame from "../src/assets/clear_tvframe1.png";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.css"
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"


const App = () => {
  const handleBackNavigation = () => {
    const currentPath = window.location.pathname;

    // Check if the current path is not "/home" or "/"
    if (currentPath !== "/home" && currentPath !== "/") {
      // Navigate to the previous page
      window.history.back();
    }
  };

  const handleHome = () => {
    window.location.href = '/Dashboard';
  };

  return (
    <ChakraProvider theme={theme}>
       <Flex
        position="relative"
        width="100svw"
        height="100svh"
        id="container"
      >
    <Flex
      backgroundImage={TVFrame}
      className="flex-container"
  h={"100%"}
  w={"100%"}
      // paddingLeft={"4%"}
      // paddingRight={"4%"}
      // paddingTop={{r:"2%", lg:"3%", md:"5%", sm:"8%"}}
      // paddingBottom={{r:"6.5%", lg:"3%", md:"19%", sm:"24%"}}
      // h={"100svh"}
      // w={"100svw"}
      // style={{
      //   backgroundSize: "100% 100%",
      //   top: "0",
      //   left: "0",
      //   position: "absolute",
      //   backgroundColor: "black",
        // paddingLeft: "4%",
        // paddingTop: "2%",
        // paddingRight: "4%",
        // paddingBottom: "6.2%",
      // }}  
      // className="flex-container"


    >
      <Flex
          backgroundColor="transparent"
          position="absolute"
          bottom="2.8%"
          left="37%"
          width="10.5%"
          height="5%"
          _hover={{ backgroundColor:"rgba(255, 255, 255, 0.5)" }} // Apply filter on hover to change brightness
          // border={"1px black solid"}
          borderRadius={"30px"}
          zIndex={10} // Higher zIndex to appear above
          onClick={handleBackNavigation}
        />
        <Flex
          backgroundColor="transparent"
          position="absolute"
          bottom="2.8%"
          right="38.5%"
          width="10.5%"
          height="5%"
          _hover={{ backgroundColor:"rgba(255, 255, 255, 0.5)" }} // Apply filter on hover to change brightness

          // border={"1px black solid"}
          borderRadius={"30px"}
          zIndex={10} // Higher zIndex to appear above
          onClick={handleHome}
        />
        <BrowserRouter>
          <Routes>
            <Route
              path="/about"
              element={
                <Suspense
                  fallback={
                    <Flex
                      backgroundColor={"rgba(93, 7, 8, 1)"}
                      w={"100%"}
                      h={"100%"}
                      borderRadius={"24"}
                      p={"1.5%"}
                      pt={"0%"}
                      color={"black"}
                      flexDir={"column"}
                      fontFamily={"Open Sans"}
                    >
                      <Flex
                        fontStyle={"italic"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"10%"}
                        w={"100%"}
                      >
                        38AIRLINES
                      </Flex>
                      <Flex
                        fontStyle={"italic"}
                        fontWeight={"Bold"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"90%"}
                        alignItems={"center"}
                        w={"100%"}
                      >
                        Entering About page ...
                      </Flex>
                    </Flex>
                  }
                >
                  {React.createElement(
                    lazy(() => Promise.resolve({ default: About }))
                  )}
                </Suspense>
              }
            />
            <Route
              path="/Dashboard"
              element={
                <Suspense fallback={<Flex
                  backgroundColor={"rgba(93, 7, 8, 1)"}
                  w={"100%"}
                  h={"100%"}
                  borderRadius={"24"}
                  p={"1.5%"}
                  pt={"0%"}
                  color={"black"}
                  flexDir={"column"}
                  fontFamily={"Open Sans"}
                >
                  <Flex fontStyle={"italic"} flexDir={"row"} fontSize={"300%"}
                    color={"white"}
                    justifyContent={"center"} h={"10%"}
                    w={"100%"}
                  >
                    38AIRLINES
                  </Flex>
                  <Flex fontStyle={"italic"} fontWeight={"Bold"} flexDir={"row"} fontSize={"300%"}
                    color={"white"}
                    justifyContent={"center"} h={"90%"} alignItems={"center"}
                    w={"100%"}>
                    Entering entertainment dashboard ...
                  </Flex>

                </Flex>}>
                  {React.createElement(
                    lazy(() => Promise.resolve({ default: Dashboard }))
                  )}
                </Suspense>
              }
            />
            <Route
              path="/Home"
              element={
                <Suspense
                  fallback={
                    <Flex
                      backgroundColor={"rgba(93, 7, 8, 1)"}
                      w={"100%"}
                      h={"100%"}
                      borderRadius={"24"}
                      p={"1.5%"}
                      pt={"0%"}
                      color={"black"}
                      flexDir={"column"}
                      fontFamily={"Open Sans"}
                    >
                      <Flex
                        fontStyle={"italic"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"10%"}
                        w={"100%"}
                      >
                        38AIRLINES
                      </Flex>
                      <Flex
                        fontStyle={"italic"}
                        fontWeight={"Bold"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"90%"}
                        alignItems={"center"}
                        w={"100%"}
                      >
                        Going to Home page ...
                      </Flex>
                    </Flex>
                  }
                >
                  {React.createElement(
                    lazy(() => Promise.resolve({ default: Home }))
                  )}
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense
                  fallback={
                    <Flex
                      backgroundColor={"rgba(93, 7, 8, 1)"}
                      w={"100%"}
                      h={"100%"}
                      borderRadius={"24"}
                      p={"1.5%"}
                      pt={"0%"}
                      color={"black"}
                      flexDir={"column"}
                      fontFamily={"Open Sans"}
                    >
                      <Flex
                        fontStyle={"italic"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"10%"}
                        w={"100%"}
                      >
                        38AIRLINES
                      </Flex>
                      <Flex
                        fontStyle={"italic"}
                        fontWeight={"Bold"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"90%"}
                        alignItems={"center"}
                        w={"100%"}
                      >
                        Going to Home page ...
                      </Flex>
                    </Flex>
                  }
                >
                  {React.createElement(
                    lazy(() => Promise.resolve({ default: Home }))
                  )}
                </Suspense>
              }
            />
            <Route
              path="/art-collection"
              element={
                <Suspense
                  fallback={
                    <Flex
                      backgroundColor={"rgba(93, 7, 8, 1)"}
                      w={"100%"}
                      h={"100%"}
                      borderRadius={"24"}
                      p={"1.5%"}
                      pt={"0%"}
                      color={"black"}
                      flexDir={"column"}
                      fontFamily={"Open Sans"}
                    >
                      <Flex
                        fontStyle={"italic"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"10%"}
                        w={"100%"}
                      >
                        38AIRLINES
                      </Flex>
                      <Flex
                        fontStyle={"italic"}
                        fontWeight={"Bold"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"90%"}
                        alignItems={"center"}
                        w={"100%"}
                      >
                        Entering Art Collection page ...
                      </Flex>
                    </Flex>
                  }
                >
                  {React.createElement(
                    lazy(() => Promise.resolve({ default: ArtCollection }))
                  )}
                </Suspense>
              }
            />
            <Route
              path="/Magazine"
              element={
                <Suspense
                  fallback={
                    <Flex
                      backgroundColor={"rgba(93, 7, 8, 1)"}
                      w={"100%"}
                      h={"100%"}
                      borderRadius={"24"}
                      p={"1.5%"}
                      pt={"0%"}
                      color={"black"}
                      flexDir={"column"}
                      fontFamily={"Open Sans"}
                    >
                      <Flex
                        fontStyle={"italic"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"10%"}
                        w={"100%"}
                      >
                        38AIRLINES
                      </Flex>
                      <Flex
                        fontStyle={"italic"}
                        fontWeight={"Bold"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"90%"}
                        alignItems={"center"}
                        w={"100%"}
                      >
                        Entering 38MAG ...
                      </Flex>
                    </Flex>
                  }
                >
                  {React.createElement(
                    lazy(() => Promise.resolve({ default: Magazine }))
                  )}
                </Suspense>
              }
            />
            <Route
              path="/Gallery"
              element={
                <Suspense
                  fallback={
                    <Flex
                      backgroundColor={"rgba(93, 7, 8, 1)"}
                      w={"100%"}
                      h={"100%"}
                      borderRadius={"24"}
                      p={"1.5%"}
                      pt={"0%"}
                      color={"black"}
                      flexDir={"column"}
                      fontFamily={"Open Sans"}
                    >
                      <Flex
                        fontStyle={"italic"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"10%"}
                        w={"100%"}
                      >
                        38AIRLINES
                      </Flex>
                      <Flex
                        fontStyle={"italic"}
                        fontWeight={"Bold"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"90%"}
                        alignItems={"center"}
                        w={"100%"}
                      >
                        Entering 38Gallery ...
                      </Flex>
                    </Flex>
                  }
                >
                  {React.createElement(
                    lazy(() => Promise.resolve({ default: Gallery }))
                  )}
                </Suspense>
              }
            />
            <Route
              path="/Watch"
              element={
                <Suspense
                  fallback={
                    <Flex
                      backgroundColor={"rgba(93, 7, 8, 1)"}
                      w={"100%"}
                      h={"100%"}
                      borderRadius={"24"}
                      p={"1.5%"}
                      pt={"0%"}
                      color={"black"}
                      flexDir={"column"}
                      fontFamily={"Open Sans"}
                    >
                      <Flex
                        fontStyle={"italic"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"10%"}
                        w={"100%"}
                      >
                        38AIRLINES
                      </Flex>
                      <Flex
                        fontStyle={"italic"}
                        fontWeight={"Bold"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"90%"}
                        alignItems={"center"}
                        w={"100%"}
                      >
                        Entering Watch38 page ...
                      </Flex>
                    </Flex>
                  }
                >
                  {React.createElement(
                    lazy(() => Promise.resolve({ default: Watch }))
                  )}
                </Suspense>
              }
            />
            <Route path="/art-collection/:ArtID" element={
              <Suspense fallback={<div>Loading...</div>}>
                {React.createElement(
                  lazy(() => Promise.resolve({ default: ViewArt }))
                )}
              </Suspense>
            }
            />
            <Route path="/Magazine/:IssueNum" element={
              <Suspense fallback={<div>Loading...</div>}>
                {React.createElement(
                  lazy(() => Promise.resolve({ default: ViewMag }))
                )}
              </Suspense>
            }
            />
            <Route path="/Watch/:index" element={
              <Suspense fallback={<div>Loading...</div>}>
                {React.createElement(
                  lazy(() => Promise.resolve({ default: ViewWatch }))
                )}
              </Suspense>
            }
            />
            <Route
              path="/Shop"
              element={
                <Suspense
                  fallback={
                    <Flex
                      backgroundColor={"rgba(93, 7, 8, 1)"}
                      w={"100%"}
                      h={"100%"}
                      borderRadius={"24"}
                      p={"1.5%"}
                      pt={"0%"}
                      color={"black"}
                      flexDir={"column"}
                      fontFamily={"Open Sans"}
                    >
                      <Flex
                        fontStyle={"italic"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"10%"}
                        w={"100%"}
                      >
                        38AIRLINES
                      </Flex>
                      <Flex
                        fontStyle={"italic"}
                        fontWeight={"Bold"}
                        flexDir={"row"}
                        fontSize={"300%"}
                        color={"white"}
                        justifyContent={"center"}
                        h={"90%"}
                        alignItems={"center"}
                        w={"100%"}
                      >
                        Entering SK38 Shop ...
                      </Flex>
                    </Flex>
                  }
                >
                  {React.createElement(
                    lazy(() => Promise.resolve({ default: Shop }))
                  )}
                </Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </Flex>
      </Flex>
      <Analytics />
      <SpeedInsights/>
    </ChakraProvider>
  );
};

export default App;
