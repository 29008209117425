import background from "../../assets/38_globes.png";

import { Flex } from "@chakra-ui/react";

import { Cloudinary } from "@cloudinary/url-gen/index";
import { AdvancedVideo } from "@cloudinary/react";
import React from "react";
import PreviousHeader from "../../components/PreviousHeader/index.tsx";

function ViewWatch() {
  const vidlink = localStorage.getItem("vidlink");
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
    },
  });

  return (
    <Flex
      backgroundImage={background}
      backgroundSize={{sm:"150%", r:"70%"}}
      backgroundPosition={"center"}
      top={0}
      left={0}
      w={"100%"}
      h={"100%"}
      borderRadius={"24"}
      p={"1.5%"}
      pt={"2%"}
      color={"black"}
      flexDir={"column"}
    >
      <PreviousHeader title="Watch38"/>

      <Flex
        w={"100%"}
        h={"90%"}
        flexDir={"row"}
        justifyContent={"center"}
        backgroundColor={"black"}
        color={"red"}
      >
        <AdvancedVideo
          cldVid={cld.video(vidlink).quality("1080")}
          controls
          width="1920"
        />
      </Flex>
    </Flex>
  );
}

export default ViewWatch;
