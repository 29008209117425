import React, { useState } from "react";
import "@fontsource/open-sans";
import {
  Flex,
  Text,
  Button,
  FormControl,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import home_background from "../../assets/home.png";
import { Field, Form, Formik } from "formik";
import axios from 'axios';
import { useNavigate } from "react-router-dom";


function FormikExample() {
  const [message, setMessage] = useState("");


  function validateName(name: any) {
    let error;
    if (!name) {
      error = "Name is required";
    }
    return error;
  }
  function validateEmail(email) {
    let error;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!email) {
      error = "Email is required";
    } else if (!emailRegex.test(email)) {
      error = "Invalid email address";
    }
    return error;
  }

  const handleSubmit = async (values, actions) => {
    const URL = "https://three8website.onrender.com"
    try {
      await axios.post(`${URL}/api/subscribe`, {
        name: values.name,
        email: values.email
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      );
      setMessage("Successfully added to the mailing list!");
      actions.resetForm({
        values: {
          name: "",
          email: ""
        }
      });
    } catch (error) {
      console.error(error);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      onSubmit={handleSubmit}
    // onSubmit={(values, actions) => {
    //   setTimeout(() => {
    //     alert(JSON.stringify(values, null, 2));
    //     actions.setSubmitting(false);
    //   }, 1000);
    // }}
    >
      {(props) => (
        <Form>
          <Field name="name" validate={validateName}>
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.name && form.touched.name}>
                <Input
                  borderRadius={0}
                  border={"2px"}
                  {...field}
                  placeholder="Name"
                  isRequired
                />
                {/* <FormErrorMessage>{form.errors.name}</FormErrorMessage> */}
              </FormControl>
            )}
          </Field>
          <Field name="email" validate={validateEmail}>
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.email && form.touched.email}>
                <Input
                  borderRadius={0}
                  border={"2px"}
                  {...field}
                  placeholder="Email Address"
                  isRequired
                />
                {/* <FormErrorMessage>{form.errors.email}</FormErrorMessage> */}
              </FormControl>
            )}
          </Field>
          {message &&
            <Text fontSize={"1rem"}>
              {message}
            </Text>
          }
          <Button
            backgroundColor={"#480E13"}
            color={"white"}
            isLoading={props.isSubmitting}
            type="submit"
            borderRadius={0}
            border={"2px"}
            fontStyle={"italic"}
            w={"100%"}
          >
            Fly!
          </Button>
        </Form>
      )}
    </Formik>
  );
}

const Home = () => {

  const navigate = useNavigate()
  return (
    <Flex
      backgroundImage={home_background}
      backgroundSize={"cover"}
      w={"100%"}
      h={"100%"}
      backgroundPosition={"center"}
      borderRadius={"24"}
      p={{ r: "1.5%", sm: "5%" }}
      pt={{ sm: "6%", r: "2%" }}
      color={"black"}
      flexDir={"column"}
    >
      <Flex
        h={"10%"}
        fontSize={"300%"}
        fontStyle={"Italic"}
        color={"white"}
        justifyContent={"space-between"}
        alignItems={"center"}
        w={"100%"}
        pl={{ r: "1.6%" }}
        fontFamily={"Open Sans"}
        mb={{ sm: "0.5rem", r: "3%", md: "3.3rem" }}
      >
        <Text
          fontWeight={"Bold"}
          flexDir={"row"}
          alignContent={"center"}
          fontSize={{ r: "100%", lg: "100%", md: "5rem", sm: "2.3rem" }}
        >
          38AIRLINES
        </Text>
      </Flex>
      <Flex h={{ r: "80%", sm: "80%", md: "90%" }} flexDirection={"column"} pr={{ r: "1%", md: "0" }} mr={{ md: "-4" }}>
        <Flex
          h={"50%"}
          justifyContent={"space-between"}
          flexDirection={{ sm: "column", md: "column", lg: "row" }}
          mb={{ sm: "3rem", lg: "", md: "1rem" }}
        >
          <Flex
            w={{ r: "40%", sm: "60%", md: "80%" }}
            fontSize={{ r: "5rem", lg: "100%", md: "5rem", sm: "2.5rem" }}
            pl={"1.6%"}
            pt={{ sm: "1.3rem", lg: "1rem", md: "1.3rem" }}
            fontWeight={"bold"}
            color={"white"}
            flexDir={"row"}
            justifyContent={{ md: "space-between" }}
            lineHeight={{ r: "90px", sm: "45px", md: "90px", }}
            order={{ sm: "1", md: "1", lg: "0" }}
          >
            Welcome aboard!
          </Flex>
          <Flex
            w={{ r: "20%", md: "45%", sm: "100%" }}
            h={{ sm: "70%", md: "50%" }}
            fontStyle={"italic"}
            flexDirection={"column"}
            justifyContent={{ r: "space-around", sm: "space-between" }}
            marginLeft={"auto"}
            textAlign={"center"}
            color={"white"}
            fontSize={{ r: "150%", sm: "80%", md: "110%" }}
            mb={{ md: "0rem" }}
          >
            Sign up for 38MILES
            {FormikExample()}
          </Flex>
        </Flex>
        <Flex
          h={"20%"}
          pl={"1.6%"}
          fontWeight={"bold"}
          color={"white"}
          fontSize={"200%"}
          flexDir={{ r: "row", sm: "column" }}
          justifyContent={{ r: "space-between", sm: "space-around" }}
          w={{ r: "45%", sm: "80%" }}
          mt={{ r: "", sm: "0.5rem" }}
        >
          <Flex fontSize={{ r: "2rem", sm: "1rem", md: "2.5rem" }}>38A</Flex>
          <Flex fontSize={{ r: "2rem", sm: "1rem", md: "2.2rem" }}>
            Toronto (YYZ) - Worldwide (∞)
          </Flex>
        </Flex>
        <Flex
          h={"25%"}
          fontSize={"150%"}
          flexDirection={"column"}
          color={"white"}
          pl={"1.6%"}
          mt={{ r: "", sm: "2rem" }}
        >
          <Flex fontSize={{ r: "1.5rem", sm: "1rem", md: "1.5rem" }}>
            To access our entertainment system
          </Flex>
          <Flex
            h={"80%"}
            flexDir={"column"}
            w={"15%"}
            justifyContent={"space-around"}
          >
            <Button
              backgroundColor={"black"}
              fontSize={{ r: "1.5rem", sm: "1rem" }}
              color={"white"}
              w={{ r: "80%", sm: "6rem" }}
              borderRadius={"12px"}
              mt={{ r: "1rem", sm: "1rem", md: "-2rem" }}
              h={{ r: "3rem", sm: "3rem" }}
              _hover={{ cursor: "pointer", backgroundColor: "white", color: "rgba(93, 7, 8, 1)" }}
              onClick={() => navigate("/Dashboard")} // Pass a function to onClick

            >
              Click Here
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Home;
