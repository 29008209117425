import React from "react";
import {
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import "@fontsource/open-sans";
import ContactModal from "../../components/ContactModal/index.tsx";
import EventModal from "../EventModal/index.tsx";
import previous from "../../assets/icons/previous.png";


// function EmailSend(email) {
//   Email.send({
//     Host: "smtp.gmail.com",
//     Username: "aaliconcern@gmail.com",
//     Password: "password",
//     To: "aaliconcern@gmail.com",
//     From: {email},
//     Subject: {email},
//     Body: {email},
//   }).then((message) => alert(message));
// }

const handleBackNavigation = () => {
  window.location.href = '/home';
};


const MainHeader = () => {
  return (
    <Flex
      h={"10%"}
      fontSize={{
        sm: "1.5rem",
        md: "2.5rem",
        r: "2rem",
        lg: "2rem",
        xl: "2rem",
      }}
      // fontSize={'2rem'}
      fontStyle={"Italic"}
      color={"white"}
      justifyContent={"space-between"}
      alignItems={"center"}
      w={"100%"}
      p={{ sm: "5%", r: "1.6%", lg:"1%" }}
      fontFamily={"Open Sans"}
    >
      {/* <Flex h={"100%"} w={"10%"} pl={"2%"} alignItems={"center"}> */}
        <Image
          src={previous}
          h={{ sm: "1.7rem", md: "3rem", r: "200%", xll: "500%" }}
          _hover={{ filter: "brightness(0.6)", cursor: "pointer" }}
          onClick={handleBackNavigation}
        />
      <Text fontWeight={"Bold"} flexDir={"row"} alignContent={"center"}>
        38AIRLINES
      </Text>
      <Flex
        w={"66%"}
        h={"200%"}
        flexDir={"column"}
        alignItems={"center"}
        justifyContent={"space-between"}
        display={{
          lg: "none",
          xl: "none",
          r: "none",
          md: "none",
          xll: "flex",
          sm: "none",
        }}
      >
        <Flex w={"100%"} h={"20%"} backgroundColor={"white"}></Flex>
        <Flex w={"100%"} h={"20%"} backgroundColor={"white"}></Flex>
        <Flex w={"100%"} h={"20%"} backgroundColor={"white"}></Flex>
      </Flex>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDir={"row"}
        w={{ sm: "25%", md: "20%", lg: "10%", xl: "10%", r: "10%", xll: "10%" }}
      >
        <EventModal/>
        <ContactModal/>
      </Flex>
    </Flex>
  );
};

export default MainHeader;
