import spinner38 from "../../assets/38.gif";
import React, { useEffect, useState } from "react";
import { Flex, Image, Spinner } from "@chakra-ui/react";


// Define the Cloudinary cloud name
const cloudName = "dvw1ar3zz"
const folderPath = "Gallery"; // Replace with your actual folder name

// Define the type for the image resource
interface CloudinaryImage {
  public_id: string;
  format: string;
  width: number;
  height: number;
}

const GalleryWidget: React.FC = () => {
  const [images, setImages] = useState<CloudinaryImage[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch images from Cloudinary folder
    const fetchImagesFromFolder = async (folderPath: string) => {
      const url = `https://res.cloudinary.com/${cloudName}/image/list/${folderPath}.json`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        const data = await response.json();

        // Fetch image dimensions
        const imagesWithDimensions = await Promise.all(
          data.resources.map(async (image: CloudinaryImage) => {
            const img = document.createElement("img") as HTMLImageElement;
            img.src = `https://res.cloudinary.com/${cloudName}/image/upload/${image.public_id}.${image.format}`;
            await new Promise((resolve) => {
              img.onload = resolve;
            });
            return {
              ...image,
              width: img.width,
              height: img.height,
            };
          })
        );
        setImages(imagesWithDimensions);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImagesFromFolder(folderPath);
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 1000); // Change image every 2 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [images]);

  if (images.length === 0) {
    return (
      <Flex
        w={"100%"}
        h={"100%"}
        flexDir={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner
          thickness="4px"
          speed="0.85s"
          emptyColor="gray.800"
          color="red.500"
          size="xl"
        />
      </Flex>
    );
  }

  const currentImage = images[currentIndex];
  const isTallImage = currentImage.height > currentImage.width;

  return (
    <Flex
      w={{ sm: "80%", r: "30%" }}
      h={"100%"}
      color={"white"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      position="relative"
    >
      <Flex
        w={"100%"}
        h={"100%"}
        backgroundImage={`url(https://res.cloudinary.com/${cloudName}/image/upload/${currentImage.public_id}.${currentImage.format})`}
        backgroundSize={isTallImage ? "cover" : "contain"}
        backgroundRepeat={"no-repeat"}
        backgroundPosition="center"
        border={"4px solid rgba(0, 0, 0, 0.2)"}
        borderRadius={"16px"}
        position="absolute"
        opacity={1}
        transition="opacity 0.5s ease-in-out"
      ></Flex>
      <Image
        src={spinner38}
        position={"absolute"}
        w={{ sm: "50%", r: "35%", lg: "25%", md: "45%" }}
      />
    </Flex>
  );
};

export default GalleryWidget;
