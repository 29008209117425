import React from "react";
import "@fontsource/open-sans";
import phone from "../../assets/icons/phone.svg";
import { Flex, Text, Image, Grid, GridItem } from "@chakra-ui/react";
import MainHeader from "../../components/MainHeader/index.jsx";
import DashboardTab from "../../components/DashboardTab/index.tsx";
import B1 from "../../assets/IMG_6397.jpg";
import B2 from "../../assets/IMG_0066.jpg";
import B3 from "../../assets/DSC01548.JPG";
import B4 from "../../assets/DSC01985.JPG";
import B5 from "../../assets/DSC01703.JPG";
import B6 from "../../assets/CN_Tower.webp";

const Dashboard = () => {
  return (
    <Flex
      backgroundColor={"rgba(93, 7, 8, 1)"}
      border={"1px solid rgba(93, 7, 8, 1)"}
      w={"100%"}
      h={"100%"}
      borderRadius={"24"}
      p={"1.5%"}
      pt={"2%"}
      color={"black"}
      flexDir={"column"}
      overflow={"auto"}
    >
      <MainHeader />
      <Flex
        justifyContent={"space-around"}
        pt={"1%"}
        backgroundColor={"rgba(93, 7, 8, 1)"}
        h={"90%"}
        // pb={{sm: "20px"}}
      >
        <Grid
          px={"20px"}
          templateColumns={{
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            r: "repeat(3, 1fr)",
          }}
          templateRows={["repeat(6, 1fr)", "repeat(2, 1fr)"]}
          gap={5}
        >
          <GridItem>
            <DashboardTab
              className="normal"
              backgroundPicture={B6}
              header="About"
              linkTo="About"
              text={
                <Flex flexDir={"row"} justifyContent={"space-between"}>
                  <Flex flexDir={"column"} justifyContent={"space-between"}>
                    <Flex flexDir={"column"} mb={"5%"}>
                      <Text fontWeight={"semi-bold"} fontSize={"100%"}>
                        Seat
                      </Text>
                      <Text
                        fontWeight={"bold"}
                        lineHeight={"0.8"}
                        fontSize={"220%"}
                      >
                        38A
                      </Text>
                    </Flex>
                    <Flex fontSize={"80%"}>
                      <Text fontWeight={"semi-bold"} fontSize={"100%"}>
                        Airplane model
                      </Text>
                    </Flex>
                    <Text fontWeight={"bold"} fontSize={"120%"}>
                      Airbus A380
                    </Text>
                  </Flex>
                  <Flex flexDir={"column"}>
                    <Flex flexDir={"column"} mb={"5%"}>
                      <Flex fontSize={"80%"}>
                        <Text
                          fontWeight={"semi-bold"}
                          fontSize={"100%"}
                          w={"100%"}
                          textAlign={"right"}
                        >
                          Outside Temperature
                        </Text>
                      </Flex>
                      <Text
                        fontWeight={"bold"}
                        fontSize={"120%"}
                        textAlign={"right"}
                      >
                        38°F
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              }
            />
          </GridItem>
          <GridItem>
            <DashboardTab
              className="normal"
              backgroundPicture={B4}
              header="38Gallery"
              linkTo="Gallery"
              text={
                <Flex
                  flexDir={"row"}
                  justifyContent={"space-between"}
                  h={"100%"}
                >
                  <Flex flexDir={"column"} justifyContent={"space-between"}>
                    <Flex flexDir={"column"} mb={"5%"}>
                      <Text fontWeight={"semi-bold"} fontSize={"100%"}>
                        Flying to
                      </Text>
                      <Text
                        fontWeight={"bold"}
                        lineHeight={"0.8"}
                        fontSize={"220%"}
                      >
                        Toronto
                      </Text>
                    </Flex>
                    <Flex fontSize={"80%"}>
                      <Text fontWeight={"semi-bold"} fontSize={"100%"}>
                        Distance left
                      </Text>
                    </Flex>
                    <Text fontWeight={"bold"} fontSize={"120%"}>
                      3800 km
                    </Text>
                  </Flex>
                  <Flex
                    flexDir={"column"}
                    h={"100%"}
                    w={{ sm: "40%", r: "30%" }}
                    justifyContent={"space-between"}
                  >
                    <Flex h={"45%"} flexDir={"column"}>
                      <Flex fontSize={"80%"}>
                        <Text
                          fontWeight={"semi-bold"}
                          fontSize={"100%"}
                          w={"100%"}
                          textAlign={"right"}
                        >
                          Local time
                        </Text>
                      </Flex>
                      <Text
                        fontWeight={"bold"}
                        fontSize={"120%"}
                        w={"100%"}
                        textAlign={"right"}
                      >
                        3:38 PM
                      </Text>
                    </Flex>
                    <Flex h={"45%"} flexDir={"column"}>
                      <Flex fontSize={"80%"}>
                        <Text
                          fontWeight={"semi-bold"}
                          fontSize={"100%"}
                          w={"100%"}
                          textAlign={"right"}
                        >
                          Local weather
                        </Text>
                      </Flex>
                      <Text
                        fontWeight={"bold"}
                        fontSize={"120%"}
                        w={"100%"}
                        textAlign={"right"}
                      >
                        Overcast
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              }
            />
          </GridItem>
          <GridItem>
            <DashboardTab
              className="normal"
              backgroundPicture={B5}
              header="38MAG"
              linkTo="Magazine"
              text={
                <Text fontWeight={"bold"} fontSize={"100%"} w={"80%"}>
                  Get 38insight through our magazine with our carefully curated articles!
                </Text>
              }
            />
          </GridItem>
          <GridItem>
            <DashboardTab
              className="normal"
              backgroundPicture={B3}
              header="38Art Collection"
              linkTo="art-collection"
              text={
                <Text fontWeight={"bold"} fontSize={"100%"} w={"80%"}>
                  Gaze on paintings by Oritsemisan Edema
                </Text>
              }
            />
          </GridItem>
          <GridItem>
            <DashboardTab
              className="normal"
              backgroundPicture={B1}
              header="Watch38"
              linkTo="Watch"
              text={
                <Text fontWeight={"bold"} fontSize={"100%"} w={"83%"}>
                  Watch our projects over the years.
                </Text>
              }
            />
          </GridItem>
          <GridItem
            // mb={{sm: "20px"}}
            pb={["20px", "0px"]}
          >
            <DashboardTab
              backgroundPicture={B2}
              header="sk38 SHOP"
              linkTo="Shop"
              text={
                ""
              }
            />
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default Dashboard;

