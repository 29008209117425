import { useState } from "react";
import {
  Box,
  Image,
  IconButton,
  Flex,
  AspectRatio,
  Heading,
  Text,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import React from "react";
import { AdvancedImage } from "@cloudinary/react";
import { useNavigate } from "react-router-dom";
import previous from "../../assets/icons/previous.png";
import next from "../../assets/icons/next.png";

import "./button.css";

interface CarouselProps {
  vidLink: string;
  title: string;
  date: string;
  description: string;
  previews: string[];
}

const Carousel: React.FC<CarouselProps> = ({
  vidLink,
  title,
  date,
  description,
  previews,
}) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [shakingLeft, setShakingLeft] = useState(false);
  const [shakingRight, setShakingRight] = useState(false);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % previews.length);
    setShakingRight(true);
    setTimeout(() => setShakingRight(false), 500); // Duration matches the CSS animation
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + previews.length) % previews.length);
    setShakingLeft(true);
    setTimeout(() => setShakingLeft(false), 500); // Duration matches the CSS animation
  };

  return (
    <Flex w={"100%"} h={"100%"} flexDir={"row"} justifyContent={"space-around"}>
      <Flex w={"10%"} h={"100%"} justifyContent={"flex-end"} mr={"7px"}>
        <Image
          src={previous}
          aria-label="Next"
          onClick={handlePrev}
          top="50%"
          h={"10%"}
          w={{ r: "50%", sm: "100%" }}
          alignSelf={"center"}
          _hover={{ filter: "brightness(0.6)", cursor: "pointer" }}
          // transform="rotate(180deg)"
          className={shakingLeft ? "shake-left" : ""}        ></Image>
      </Flex>

      <Flex
        w={"80%"}
        h={"100%"}
        flexDir={"column"}
        justifyContent={"space-around"}
        backgroundImage={previews[currentIndex]}
        backgroundSize={{ r: "contain", sm: "cover" }}
        backgroundPosition={"center"}
        // "images[currentIndex]"
        _hover={{ filter: "brightness(0.6)", cursor: "pointer" }}
        onClick={() => {
          navigate(`/Watch/${currentIndex}`);
          localStorage.setItem("vidlink", vidLink[currentIndex]);
        }} // Pass a function to onClick
      >
        <Flex
          width="100%"
          height="100%"
          border={"4px solid rgba(0, 0, 0, 0.2)"}
          background="rgba(0, 0, 0, 0.4)" // Adjust opacity for the glass effect
          backdropFilter="blur(3px)" // Adjust blur radius for the glass effect
          flexDir={"column"}
          p={"4%"}
          color={"white"}
        >
          <Flex
            w={"100%"}
            h={"100%"}
            flexDir={"column"}
            pl={"2%"}
            pt={"2%"}
            color={"white"}
          >
            <Heading fontSize={{ r: "300%", sm: "180%" }}>
              {title[currentIndex]}
            </Heading>
            <Text fontSize={{ r: "200%", sm: "120%" }}>
              {" "}
              {date[currentIndex]}{" "}
            </Text>
            <br></br>
            <Text
              fontSize={{ r: "130%", sm: "100%" }}
              mt={"2%"}
              w={"90%"}
              fontStyle={"italic"}
            >
              {description[currentIndex]}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {/* <Flex w={"80%"} h={"100%"} backgroundImage={images[currentIndex]} backgroundSize={"contain"} backgroundPosition={"center"} backgroundRepeat={"no-repeat"} backgroundColor={"transparent"}></Flex> */}
      <Flex
        w={"10%"}
        h={"100%"}
        ml={"7px"}
        justifyContent={"space-around"}
        flexDir={"column"}
      >
        <Image
          src={next}
          aria-label="Next"
          onClick={handleNext}
          top="50%"
          h={"10%"}
          w={{ r: "50%", sm: "100%" }}
          alignSelf={"left"}
          _hover={{ filter: "brightness(0.3)", cursor: "pointer" }}
          className={shakingRight ? "shake-right" : ""}        ></Image>
        {/* <IconButton
          icon={<ChevronRightIcon/>}
          aria-label="Next"
          onClick={handleNext}
          top="50%"
          transform="translateY(-50%)"
        /> */}
      </Flex>
    </Flex>
  );
};

export default Carousel;
