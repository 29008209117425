import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  Input,
  Textarea,
  FormLabel,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import "@fontsource/open-sans";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import modalbackground from "../../assets/38.gif";
import React, { useRef, useState } from "react";
import { Form, Formik, Field } from "formik";
import phone from "../../assets/icons/phone.svg";
import emailjs from '@emailjs/browser';

function FormikExample() {

  const [message, setMessage] = useState("");

  function validateName(name: any) {
    let error;
    if (!name) {
      error = "Name is required";
    }
    return error;
  }

  function validateEmail(email) {
    let error;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!email) {
      error = "Email is required";
    } else if (!emailRegex.test(email)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validateOrderNumber(ordernumber) {
    let error;
    const emailRegex = /^[0-9]+$/;
    if (!ordernumber) {
      error = "Order number required for order support";
    } else if (!emailRegex.test(ordernumber)) {
      error = "Invalid order number";
    }
    return error;
  }
  function validateMessage(message) {
    let error;
    if (!message) {
      error = "No Message written";
    }
    return error;
  }

  const form = useRef();
  
  const sendEmail = () => {
    // e.preventDefault();

    const formElement = document.getElementById('your-form-id');
    emailjs
      .sendForm('service_fa8vgp6', 'contact_form', formElement, {
        publicKey: 'rG-YayS1T3XkGhO5_',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error);
        },
      );
  };

  return (
    <Formik
      initialValues={{ user_name: "", user_email: "", order_number:"", message:""}}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          sendEmail()
          setMessage("Message sent to 38Team!");
          // alert(JSON.stringify(values, null, 2));
          actions.setSubmitting(false);
          actions.resetForm();
        }, 1000);
      }}
    >
      {(props) => (
        <Form id="your-form-id">
          <Field name="user_name" validate={validateName} >
            {({ field, form }) => (
              <FormControl
                display={"flex"}
                w={{r:"31.25rem", sm:"100%"}}
                pb={"2%"}
                borderColor={"red"}
                borderStyle={"dashed"}
                isRequired
                isInvalid={form.errors.name && form.touched.name}
              >
                <FormLabel
                  pt={2}
                  alignSelf="center"
                  textAlign="right"
                  w={"100px"}
                >
                  Name
                </FormLabel>
                <Input
                  {...field}
                  flex="1"
                  placeholder="Cole"
                  borderRadius={0}
                  border={"2px"}
                />
                {/* <FormErrorMessage>{form.errors.name}</FormErrorMessage> */}
              </FormControl>
            )}
          </Field>
          <Field name="user_email" validate={validateEmail} >
            {({ field, form }) => (
              <FormControl
                display={"flex"}
                w={{r:"31.25rem", sm:"100%"}}
                pb={"2%"}
                borderColor={"red"}
                borderStyle={"dashed"}
                isRequired
                // isInvalid={form.errors.email && form.touched.email}
              >
                <FormLabel
                  pt={2}
                  alignSelf="center"
                  textAlign="right"
                  w={"100px"}
                >
                  Email
                </FormLabel>
                <Input
                  {...field}
                  flex="1"
                  placeholder="cole@example.com"
                  borderRadius={0}
                  border={"2px"}
                />
                {/* <FormErrorMessage>{form.errors.email}</FormErrorMessage> */}
              </FormControl>
            )}
          </Field>
          <Field name="order_number" validate={validateOrderNumber}>
            {({ field, form }) => (
              <FormControl
                display={"flex"}
                w={{r:"31.25rem", sm:"100%"}}
                pb={"2%"}
                borderColor={"red"}
                borderStyle={"dashed"}
                // isInvalid={form.errors.ordernumber && form.touched.ordernumber}
              >
                <FormLabel
                  pt={2}
                  alignSelf="center"
                  textAlign="right"
                  w={"100px"}
                >
                  Order Num 
                </FormLabel>
                <Input
                  {...field}
                  flex="1"
                  placeholder="38001"
                  borderRadius={0}
                  border={"2px"}
                />
                {/* <FormErrorMessage mb={"10px"}>
                  {form.errors.ordernumber}
                </FormErrorMessage> */}
              </FormControl>
            )}
          </Field>
          <Field name="message" validate={validateMessage} isRequired>
            {({ field, form }) => (
              <FormControl
                display={"flex"}
                w={{r:"31.25rem", sm:"100%"}}
                pb={"2%"}
                borderColor={"red"}
                borderStyle={"dashed"}
                isInvalid={form.errors.message && form.touched.message}
                isRequired
              >
                <FormLabel
                  pt={2}
                  alignSelf="center"
                  textAlign="right"
                  w={"100px"}
                >
                  Message
                </FormLabel>
                <Textarea
                  {...field}
                  flex="1"
                  borderRadius={0}
                  border={"2px"}
                  placeholder="I want a custom pair of pants!"
                ></Textarea>
                {/* <FormErrorMessage mb={"10px"}>
                  {form.errors.message}
                </FormErrorMessage> */}
              </FormControl>
            )}
          </Field>
          {message &&
            <Text textAlign={"center"} fontSize={"1rem"}>
              {message}
            </Text>
          }
          <Button
            mt={4}
            w={{r:"31.25rem", sm:"100%"}}
            colorScheme="teal"
            isLoading={props.isSubmitting}
            type="submit"
            backgroundColor={"#480E13"}
            color={"white"}
            borderRadius={0}
            border={"2px"}
            fontStyle={"italic"}
          >
            Send to 38
          </Button>
        </Form>
      )}
    </Formik>
  );
}

const ContactModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Image
        src={phone}
        alt="Phone Icon"
        h={{ sm: "1.7rem", md: "3rem", r: "70%" }}
        _hover={{ filter: "brightness(0.6)", cursor: "pointer" }}
        onClick={onOpen}
      ></Image>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{sm:"100vw", r:"50vw"}} // Adjust the maximum width as needed
          maxH={{sm:"100vw", r:"50vw"}}
          backgroundColor={"white"}
          display="flex"
          alignItems={{ r: "center", sm: "center", md: "center" }}
          justifyContent="center"
        >
          <ModalHeader fontFamily={"monospace"}>
            38AIRLINES CONTACT CARD
            <ModalCloseButton backgroundColor={"white"} />
          </ModalHeader>
          <ModalBody>
            <FormikExample />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactModal;
