import React, { FC, useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import MagazineCover from "../../assets/38_Magazine.jpg";

import "@fontsource/open-sans";

interface MagazineTab {
  IssueNum: string;
}

const MagazineTab: FC<MagazineTab> = ({ IssueNum }) => {
  const navigate = useNavigate();
  const handleProductClick = (IssueNum: string) => {
    // Use the product title and id to create the path
    // const path = `/product/${productTitle.replace(/\s+/g, '-').toLowerCase()}-${productID}`;
    const path = `/Magazine/${IssueNum}`;

    // Navigate to the product view page
    navigate(path);
  };

  return (
    <Box
      w={"208px"}
      h={"340px"}
      mb={"40px"}
      // py={"8px"}
      // px={"4px"}
      border={"4px solid rgba(17, 17, 26, 0.18)"}
      _hover={{
        boxShadow: "1px 2px 35px 0px rgba(17, 17, 26, 0.18)",
        cursor: "pointer",
        transition: "0.3s ease-in-out",
        backgroundColor: "rgba(93, 7, 8, 1)",
      }}
      borderRadius={"4px"}
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      backgroundColor={"rgba(93, 7, 8, 0.7)"}
      onClick={() => handleProductClick(IssueNum)}
    >
      <Box w={"100%"} h={"90%"}>
        {/* <Flex h={"208px"} w={"208px"}> */}
        <Image
          width="100%"
          height="100%"
          alignSelf={"center"}
          src={MagazineCover}
        />
        {/* <AdvancedImage cldImg={myImage} /> */}
        {/* <Image
            src={myImage}
            alt={ArtTitle}
            width={"100%"}
            height={"100%"}
          /> */}
        {/* </Flex> */}
      </Box>
      <Flex
          fontSize={"20px"}
          w={"100%"}
          fontWeight={1000}
          color={"white"}
          mt={"5px"}
          align={"start"}
          px={"4px"}
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text
            noOfLines={3}
            lineHeight={"22px"}
            fontStyle={"italic"}
            color={"white"}
            flexDir={"column"}
          justifyContent={"center"}
          >
        Issue #{IssueNum}
          </Text>
        </Flex>
      {/* <Flex
        noOfLines={3}
        lineHeight={"22px"}
        fontStyle={"italic"}
        color={"gray"}
      >
        <Text>
        Issue #1

        </Text>
      </Flex> */}
    </Box>
  );
};

export default MagazineTab;
