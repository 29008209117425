import React, { FC, useEffect, useState } from "react";
import { Box, Button, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import "@fontsource/open-sans";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { AdvancedImage } from "@cloudinary/react";
import { Resize } from "@cloudinary/url-gen/actions/resize";
import { CloudinaryImage } from "@cloudinary/url-gen/assets/CloudinaryImage";
import { Cloudinary } from "@cloudinary/url-gen/index";

interface ArtTab {
  ArtTitle: string;
  ArtPreview: string;
  ArtDesc: string;
  ArtID: string;
  ArtSize: string;
  ArtLink: string;
  onClick?: any;
}

const ArtTab: FC<ArtTab> = ({ ArtTitle, ArtID, ArtPreview, ArtDesc, ArtLink, ArtSize }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
    }
  });

  const navigate = useNavigate();
  const handleProductClick = (ArtID: string) => {
    // Use the product title and id to create the path
    // const path = `/product/${productTitle.replace(/\s+/g, '-').toLowerCase()}-${productID}`;
    const path = `/art-collection/${ArtID}`;

    // Navigate to the product view page
    navigate(path);
    localStorage.setItem("artlink", ArtLink);
    localStorage.setItem("artsize", ArtSize);

  };

  return (
    <Box
      w={"190px"}
      h={"340px"}
      mb={"40px"}
      // py={"8px"}
      // px={"4px"}
      _hover={{
        boxShadow: "1px 2px 35px 0px rgba(17, 17, 26, 0.18)",
        cursor: "pointer",
        transition: "0.3s ease-in-out",
        backgroundColor: "rgba(93, 7, 8, 1)",
      }}
      borderRadius={"4px"}
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      backgroundColor={"rgba(93, 7, 8, 0.7)"}
      onClick={() => handleProductClick(ArtID)}
     

    >
      <Box w={"100%"}>
        <Flex h={"190px"} w={"190px"}>
          <Image
            width="100%"
            height="100%"
            alignSelf={"center"}
            src={ArtPreview}
          />
        </Flex>
        <Flex
          color={"white"}
          fontSize={"14px"}
          flexDirection={"column"}
          mt={"10px"}
          py={"8px"}
          px={"4px"}
        >
          <Text
            noOfLines={1}
            lineHeight={"22px"}
            fontSize={"150%"}
            fontWeight={"semi-bold"}
          >
            <strong>{ArtTitle}</strong>
          </Text>
        </Flex>
        <Flex
          fontSize={"14px"}
          w={"100%"}
          fontWeight={500}
          color={"white"}
          mt={"5px"}
          align={"start"}
          px={"4px"}
        >
          <Text
            noOfLines={3}
            lineHeight={"22px"}
            fontStyle={"italic"}
            color={"gray"}
          >
            <strong>{ArtDesc}</strong>
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default ArtTab;
