// import React, { useState, useEffect } from "react";
// import app from "../firebaseConfig";
// import { getDatabase, ref, get } from "firebase/database";
// import Carousel from "../components/Carousel/index.tsx";
import { Flex } from "@chakra-ui/react";

// function ReadWatch() {
//   const [vidData, setVidData] = useState({});

//   useEffect(() => {
//     fetchData();
//   }, []); // Empty dependency array means this effect runs only once, equivalent to componentDidMount

//   const fetchData = async () => {
//     try {
//       const db = getDatabase(app);
//       const dbRef = ref(db, "thirty8/videos");
//       const snapshot = await get(dbRef);
//       if (snapshot.exists()) {
//         setVidData(snapshot.val());
//       } else {
//         alert("No data available");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   return (
//     <>
//       {vidData ? (
//         Object.keys(vidData).map((key) => (
//           // <Flex w={"100px"} h={"100px"} color={"white"}>
//           //   {vidData[key].title}
//           //   <br></br>
//           //   {vidData[key].desc}
//           //   <br></br>
//           //   {vidData[key].date}
//           //   <br></br>
//           //   {vidData[key].preview}
//           //   <br></br>
//           //   {vidData[key].link}

//           // </Flex>
//           <Carousel key={key} title={vidData[key].desc} />
//         ))
//       ) : (
//         <p>Loading...</p>
//       )}
//     </>

//     // <div>
//     //   <pre>{JSON.stringify(artData, null, 2)}</pre>
//     // </div>
//   );
// }

// export default ReadWatch;

import React, { useState, useEffect } from "react";
import app from "../firebaseConfig";
import { getDatabase, ref, get } from "firebase/database";
import Carousel from "../components/Carousel/index.tsx";
import { Spinner } from "@chakra-ui/react";

function ReadWatch() {
  const [vidData, setVidData] = useState({
    titles: [],
    descriptions: [],
    dates: [],
    previews: [],
    links: []
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const db = getDatabase(app);
      const dbRef = ref(db, "thirty8/videos");
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const titles = [];
        const descriptions = [];
        const dates = [];
        const previews = [];
        const links = [];

        Object.keys(data).forEach((key) => {
          const video = data[key];
          titles.push(video.title);
          descriptions.push(video.desc);
          dates.push(video.date);
          previews.push(video.preview);
          links.push(video.link);
        });

        setVidData({
          titles,
          descriptions,
          dates,
          previews,
          links
        });
      } else {
        alert("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {vidData.titles.length > 0 ? (
        <Carousel
          title={vidData.titles}
          description={vidData.descriptions}
          date={vidData.dates}
          previews={vidData.previews}
          vidLink={vidData.links}
        />
      ) : (
        <Flex w={"100%"} h={"100%"} flexDir={"row"} justifyContent={"center"} alignItems={"center"}>
        <Spinner
        thickness='4px'
        speed='0.85s'
        emptyColor='gray.800'
        color='red.500'
        size='xl'
      />
      </Flex>
      )}
    </>
  );
}

export default ReadWatch;
