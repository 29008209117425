import { Flex } from "@chakra-ui/react";
import background from "../../assets/38_globes.png";
import PreviousHeader from "../../components/PreviousHeader/index.tsx";
import React from "react";

const Shop = () => {
  return (
    <Flex
      backgroundImage={background}
      backgroundSize={{sm:"150%", r:"70%"}}
      backgroundPosition={"center"}
      top={0}
      left={0}
      w={"100%"}
      h={"100%"}
      borderRadius={"24"}
      p={"1.5%"}
      pt={"2%"}
      color={"black"}
      flexDir={"column"}
    >
      <PreviousHeader title="SK38 SHOP" />
      <iframe allowFullScreen src="https://www.yahoo.com/" width="100%" height="100%">

      </iframe>
    </Flex>
  );
};

export default Shop;
