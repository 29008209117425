import background from "../../assets/38_globes.png";
import PreviousHeader from "../../components/PreviousHeader/index.tsx";
import ReadWatch from "../../components/readWatch.js";
import { Flex } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import React, { Suspense } from 'react';


function Watch() {
  return (
    <Flex
      backgroundImage={background}
      backgroundSize={{sm:"150%", r:"70%"}}
      backgroundPosition={"center"}
      top={0}
      left={0}
      w={"100%"}
      h={"100%"}
      borderRadius={"24"}
      p={"1.5%"}
      pt={"2%"}
      color={"black"}
      flexDir={"column"}
      zIndex={0}
    >
      <PreviousHeader title="Watch38" />
      <Flex
        h={{ r: "90%", sm: "50%" }}
        w={{ r: "100%", sm: "100%" }}
        mt={{ r: "1rem", sm: "7rem" }}
      >
        <Suspense
            fallback={
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            }
          >
        <ReadWatch />
        </Suspense>
      </Flex>
    </Flex>
  );
}

export default Watch;
