import React from "react";
import "@fontsource/open-sans";
import misan from "../../assets/boarding-passes/misan.png";
import daisy from "../../assets/boarding-passes/daisy.png";
import daniel from "../../assets/boarding-passes/daniel.png";
import korede from "../../assets/boarding-passes/korede.png";
import background_img from "../../assets/about_bg.png";
import BoardingPass from "../../components/BoardingPass/index.tsx";
import instagram from "../../assets/icons/instagram-white.png";
import tiktok from "../../assets/icons/tiktok-white.png";
import mail from "../../assets/icons/mail-white.png";


import {
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import PreviousHeader from "../../components/PreviousHeader/index.tsx";

const About = () => {
  return (
    <Flex
      w={"100%"}
      backgroundImage={background_img}
      backgroundSize={{ r: "100% 100%", sm: "200% 100%" }}
      backgroundPosition={"center"}
      top={0}
      left={0}
      padding={"1.5%"}
      // paddingRight={"0%"}
      borderRadius={"24"}
      flexDir={"column"}
      overflow={"auto"}
    >
      <PreviousHeader title="About"></PreviousHeader>
      <Flex h={"100%"} w={"100%"} p={"1.5%"}>
        <Flex w={{ r: "45%", md: "35%", sm: "45%", lg:"40%" }} flexDir={"column"}>
          <Text
            color={"white"}
            pt={"4%"}
            fontWeight={"bold"}
            fontSize={{ r: "180%", sm: "90%", md: "150%" }}
            mb={"1rem"}
          >
            This is a multidisciplinary creative house, inviting the
            collaboration of various creative disciplines, working on numerous
            projects. <br />
            <br />
            We strive to recognize and invest in emerging individuals under
            and/or working with us.
            <br />
            <br />
            Our main priority is His kingdom.
            <br />
            We start with God and finish with God.
          </Text>
          <Flex flexDir={"row"} justifyContent={"space-between"} w={"90%"}>
          <Link href="https://www.instagram.com/38dz9s/" target="_blank"
 w={{sm:"20%", md:"20%", r:"15%"}}>
          <Image src={instagram} _hover={{ filter: 'brightness(0.5)' }} />
          </Link>
          <Link href="https://www.tiktok.com/@38dz9s" target="_blank" w={{sm:"20%", md:"20%", r:"15%"}}>
          <Image src={tiktok} _hover={{ filter: 'brightness(0.5)' }}/>
          </Link>
          <Link href="mailto:info@thirty8.xyz" w={{sm:"28%", md:"20%", r:"15%"}}>
          <Image src={mail}  w={"100%"}       _hover={{ filter: 'brightness(0.5)' }} // Apply filter on hover to change brightness
/>
          </Link>
          </Flex>
        </Flex>
        <Flex
          w={{ r: "55%", md: "68%", sm: "55%", lg: "60%" }}
          flexDir={"column"}
          justifyContent={"center"}
          pr={{ sm: "5", r: "5%", md: "0" }}
          mr={{ sm: "-1%", r: "", md: "-10%" }}
          overflow={{ sm: "clip", r: "inherit", lg: "inherit"}}
        >
          <Heading
            fontSize={{ r: "150%", md: "120%", sm: "80%" }}
            color={"white"}
            textAlign={"right"}
            fontStyle={"italic"}
            h={"5%"}
            mt={{ r: "20%", sm: "-90%", md: "-30%", lg:"30%" }}
            mb={{ xll: "0", r:"-20%", sm: "-30%", md: "15%" }}
          >
            Meet the team by selecting a boarding pass
          </Heading>
          <Flex
            h={{ r: "120%", sm: "68%", md: "55%", lg: "110%" }}
            alignItems="flex-end"
            justifyContent="flex-end"
            transform={{
              sm: "rotate(-90deg)",
              r: "none",
              md: "rotate(-90deg)",
              lg: "rotate(-90deg)",
            }}
            // marginBottom={{sm:"20%", r:""}}
            // paddingBottom={{sm:"10%", r:""}}
          >
            <BoardingPass
              boardingPassImage={daniel}
              boardingPassAltText={"daniel-boarding-pass"}
              boardingPassTransformOrigin={"bottom left"}
              boardingPassTransform={"rotate(-65deg)"}
              boardingPassHeight={{sm:"70%", md:"90%", xll:"90%", r:"70%", lg:"70%"}}
              boardingPassMarginRight={"-30%"}
            ></BoardingPass>
            <BoardingPass
              boardingPassImage={korede}
              boardingPassAltText={"korede-boarding-pass"}
              boardingPassTransformOrigin={"bottom left"}
              boardingPassTransform={"rotate(-45deg)"}
              boardingPassHeight={{sm:"70%", md:"90%", xll:"90%", r:"70%", lg:"70%"}}
              boardingPassMarginRight={"-30%"}
            ></BoardingPass>
            <BoardingPass
              boardingPassImage={daisy}
              boardingPassAltText={"daisy-boarding-pass"}
              boardingPassTransformOrigin={"bottom left"}
              boardingPassTransform={"rotate(-20deg)"}
              boardingPassHeight={{sm:"70%", md:"90%", xll:"90%", r:"70%", lg:"70%"}}
              boardingPassMarginRight={"-30%"}
            ></BoardingPass>
            <BoardingPass
              boardingPassImage={misan}
              boardingPassAltText={"misan-boarding-pass"}
              boardingPassTransformOrigin={"bottom left"}
              boardingPassTransform={"rotate(5deg)"}
              boardingPassHeight={{sm:"70%", md:"90%", xll:"90%", r:"70%", lg:"70%"}}
              boardingPassMarginBottom={{r:"3%"}}
            ></BoardingPass>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default About;

// <Flex
// backgroundImage={background_img}
// backgroundSize={"100% 100%"} backgroundPosition={"center"}
// top={0}
// left={0}
// w={"100%"}
// h={"100%"}
// borderRadius={"24"}
// p={"1.5%"}
// pt={"0%"}
// color={"black"}
// flexDir={"row"}
// justifyContent={"space-around"}
// overflow={"auto"}
// >
// <Flex  h="100%" w="85%"  flexDir={"column"} fontFamily={"Open Sans"}>
// <Flex h={"10%"} ml={"2%"} alignItems={"center"}>
// <Image h={"70%"} src={house}></Image>
// </Flex>
//     <Flex h={"90%"} w={"100%"}>
//       <Flex h={"100%"} w={"45%"} pl={"2%"} flexDir={"column"}>
//         <Heading color={"white"} fontSize={"500%"} pt={"1%"}>
//           About
//         </Heading>

//       </Flex>
//       <Flex h={"100%"} w={"55%"} flexDir={"column"}>

//       </Flex>
//     </Flex>
// </Flex>
// </Flex> */
// }
