import "@fontsource/open-sans";
import { Flex } from "@chakra-ui/react";
import background from "../../assets/38_globes.png";
import PreviousHeader from "../../components/PreviousHeader/index.tsx";
import React from "react";
import "jquery-ui";
import FlipBook from "../../components/Magbook/index.tsx";
import MagazineTab from "../../components/MagazineTab/index.tsx";

function Magazine() {
  return (
    // <Divider w={"1000px"} ref={galleryRef}> </Divider>
    // <Flex w={"100%"} h={"100%"}>
    <Flex
      backgroundImage={background}
      backgroundSize={{sm:"150%", r:"70%"}}
      backgroundPosition={"center"}
      top={0}
      left={0}
      w={"100%"}
      h={"100%"}
      borderRadius={"24"}
      p={"1.5%"}
      pt={"2%"}
      color={"black"}
      flexDir={"column"}
      alignContent={"flex-end"}
    >
      <PreviousHeader title="38MAG" />
      <Flex
      flexDir={"row"}
      justifyContent={"center"}
      h={"90%"}
      alignItems="center">
      <MagazineTab IssueNum="1"/>

      </Flex>
    </Flex>
  );
}

export default Magazine;



// <Box width="400px" height="600px">
// <FlipPage>
//   <div>Page 1</div>
//   <div>Page 2</div>
//   {/* {pages.map((pageContent, index) => (
//     <FlipPage.Page key={index}>
//       <Box
//         height="100%"
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//         fontSize="2xl"
//         border="2px solid"
//         borderColor="gray.600"
//         borderRadius="md"
//         p={4}
//       >
//         {pageContent}
//       </Box>
//     </FlipPage.Page>
//   ))} */}
// </FlipPage>
// </Box>