import "@fontsource/open-sans";
import { Flex } from "@chakra-ui/react";
import background from "../../assets/38_globes.png";
import React, { useEffect, useState } from "react";
import "jquery-ui";
import { useParams } from "react-router-dom";
import PreviousHeader from "../../components/PreviousHeader/index.tsx";
import { Spinner } from "@chakra-ui/react";

const ViewMag = () => {

  const [isLoading, setIsLoading] = useState(true); // State to manage loading state

useEffect(() => {
  // Simulate loading delay
  const timeout = setTimeout(() => {
    setIsLoading(false);
  }, 2000); // Adjust the delay as needed

  return () => clearTimeout(timeout); // Cleanup function
}, []);

  const { IssueNum } = useParams();

  return (
    <Flex
      backgroundImage={background}
      backgroundSize={{sm:"150%", r:"70%"}}
      backgroundPosition={"center"}
      top={0}
      left={0}
      w={"100%"}
      h={"100%"}
      borderRadius={"24"}
      p={"1.5%"}
      pt={"2%"}
      color={"black"}
      flexDir={"column"}
      alignContent={"flex-end"}
      overflow={"clip"}
    >
      <PreviousHeader title={`Issue #${IssueNum}`}/>
      <Flex
        flexDir={"row"}
        justifyContent={"center"}
        h={"100%"}
        alignItems="center"
      >
    {isLoading ? (
      <Flex w={"100%"} h={"100%"} flexDir={"row"} justifyContent={"center"} alignItems={"center"}>
        <Spinner
          thickness='4px'
          speed='0.85s'
          emptyColor='gray.800'
          color='red.500'
          size='xl'
        />
      </Flex>
    ) : (
      <iframe
        allowFullScreen
        scrolling="no"
        // className="fp-iframe"
        src="https://heyzine.com/flip-book/158fe0f179.html"
        style={{ border: "0px", width: "100%", height: "100%" }}
      ></iframe>
    )}
      </Flex>
    </Flex>
  );
};

export default ViewMag;

// <Box width="400px" height="600px">
// <FlipPage>
//   <div>Page 1</div>
//   <div>Page 2</div>
//   {/* {pages.map((pageContent, index) => (
//     <FlipPage.Page key={index}>
//       <Box
//         height="100%"
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//         fontSize="2xl"
//         border="2px solid"
//         borderColor="gray.600"
//         borderRadius="md"
//         p={4}
//       >
//         {pageContent}
//       </Box>
//     </FlipPage.Page>
//   ))} */}
// </FlipPage>
// </Box>
