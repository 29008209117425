import React, { Suspense, useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import "@fontsource/open-sans";
import phone from "../../assets/icons/phone.svg";
import { Flex, Text, Image, Img } from "@chakra-ui/react";
import background from "../../assets/38_globes.png";
import PreviousHeader from "../../components/PreviousHeader/index.tsx";
import GalleryWidget from "../../components/GalleryWidget/index.tsx";

const Gallery = () => {
  return (
    <Flex
      // backgroundImage={background}
      // backgroundSize={{sm:"150%", r:"50% 100%"}}
      // backgroundPosition={"left"}
      backgroundColor={"black"}
      top={0}
      left={0}
      w={"100%"}
      h={"100%"}
      borderRadius={"24"}
      p={"1.5%"}
      pt={"2%"}
      color={"black"}
      flexDir={"column"}
      alignItems={"center"}
    >
      <PreviousHeader title="Gallery" />
      <Flex w={"100%"} h={"100%"} justifyContent={"center"} mt={"1%"}>
        <Flex
          w={{ r: "35%", sm: "10%" }}
          h={"100%"}
          backgroundImage={background}
          backgroundSize={{ sm: "150%", r: "100%" }}
          backgroundPosition={"center"}
          borderRadius={"24"}
        ></Flex>
        <GalleryWidget />
        <Flex
          w={{ r: "35%", sm: "10%" }}
          backgroundImage={background}
          backgroundSize={{ sm: "150%", r: "100%" }}
          backgroundPosition={"center"}
          borderRadius={"24"}
        ></Flex>
      </Flex>
    </Flex>
  );
};

export default Gallery;
